import React from 'react';

// import i18n from '../../i18n';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './ButtonRound.module.scss';
import { ucFirst } from '../../utils/caseconverters';
import Icon from '../../components/Icon';
import { allIcons } from '../Icon/Icon';

const ButtonRound = ({
    type = 'facebook',
    href = '',
    onClick = () => {},
    modalSize = false,
    iconHoverColor = null,
    label = '',
    modifiers = [],
}) => {
    const AnchorOrButton = href ? 'a' : 'button';

    modifiers = [...modifiers, styles['ButtonRound--Active']];

    return (
        !!type && (
            <div className={styles['ButtonRound']}>
                <AnchorOrButton
                    href={href}
                    onClick={onClick}
                    className={styles['ButtonRound__Link']}>
                    <span className="sr-only">{label}</span>
                </AnchorOrButton>

                <Icon
                    type={type}
                    color="white"
                    size="large"
                    round={true}
                    modalSize={modalSize}
                    hoverColor={iconHoverColor}
                    modifiers={modifiers}
                />
            </div>
        )
    );
};

ButtonRound.propTypes = {
    href: PropTypes.string,
    type: PropTypes.oneOf(Object.keys(allIcons)).isRequired,
    modalSize: PropTypes.bool,
    iconHoverColor: PropTypes.oneOf(['white', 'black800', 'pink']),
    modifiers: PropTypes.array,
    label: PropTypes.string,
};

export default ButtonRound;
