import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './Modal.module.scss';
import { isolateTabs } from '../../utils/a11y';

const Modal = ({
    children,
    handleCloseModal,
    ariaLabelledby,
    ariaDescribedby,
    modifiers = [],
}) => {
    if (!children) {
        return null;
    }

    useEffect(() => {
        function keyListener(e) {
            const listener = keyListenersMap.get(e.keyCode);
            return listener && listener(e);
        }

        document.addEventListener('keydown', keyListener);

        return () => document.removeEventListener('keydown', keyListener);
    }, []);

    const modalRef = useRef();
    const handleTabKey = (e) => {
        isolateTabs(e, modalRef);
    };

    const keyListenersMap = new Map([
        [27, handleCloseModal],
        [9, handleTabKey],
    ]);

    const classes = classNames(
        styles['Modal'],
        modifiers.map((mod) => styles['Modal--' + mod])
    );

    return (
        <div
            className={classes}
            role="dialog"
            aria-labelledby={ariaLabelledby}
            aria-describedby={ariaDescribedby}
            aria-modal="true"
            onClick={(e) => e.stopPropagation()}>
            <div className={styles['Modal__ChildrenWrapper']} ref={modalRef}>
                {children}
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.arrayOf(PropTypes.node),
        PropTypes.node,
    ]).isRequired,
    modal: PropTypes.array,
};

export default Modal;
